


































import { getTzktDomain } from '@/utils/utilities';
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
@Component
export default class OperationLoadingDialog extends Vue {
    @Prop()
    success!: boolean

    @Prop()
    loading!: boolean

    @Prop()
    opeHash!: string

    @PropSync('active', {type: Boolean})
    activeDialog!: boolean

    loadComp = null

    openLoading () {
      this.loadComp = this.$vs.loading({
          target: (this.$refs['loadDiv'] as Element[]),
          text: 'Loading...',
          type: 'circles'
      })
    }

    closeDialog () {
        if (this.success) {
          this.$emit('close-on-success')
        }
        this.activeDialog = false
    }

    get transactionLink () {
        if (this.$config && this.opeHash) {
            let tzKtDomain = getTzktDomain(this.$config?.networkTzkt)
            return `${tzKtDomain}${this.opeHash}`
        }
        return ''
    }

    @Watch('loading')
    onChangeLoading() {
      if (!this.loading && this.loadComp) {
          (this.loadComp as any).close()
      }
    }

    @Watch('activeDialog')
    onChangeActiveDialog() {
      if (this.activeDialog && this.loading) {
          this.$nextTick(() => {
            this.openLoading()
          })
      }
    }
}
