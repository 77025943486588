import { tzip12 } from '@taquito/tzip12';
import { TrooperzMetadata, ContractTypeTZIP, TZIP12, TrooperzStatsData, MissionMode, ModeParams, BatchParams, ConsumablesParams, LotConfig } from '@/type';
import { ContractAbstraction, ContractProvider, OpKind, WalletParamsWithKind } from '@taquito/taquito';
import { Extension, TezosToolkit, Wallet } from "@taquito/taquito";

export async function getTrooperzMetadata (tezos: TezosToolkit, trooperzId: number, tokenContractAddress: string): Promise<TrooperzMetadata|null> {
    const contract: ContractTypeTZIP = await tezos.contract.at(tokenContractAddress, tzip12 as TZIP12)
    const data = await getTrooperzMetadataWithContract(contract, trooperzId)
    return data
}

export async function getTrooperzStats (tezos: TezosToolkit, trooperzId: number, tokenContractAddress: string): Promise<TrooperzStatsData> {
    const storage = await getContractStorage(tezos, tokenContractAddress)
    const stats = await storage.trooperz_attributes.get(trooperzId)
    const xp = await storage.xp_map.get(trooperzId)
    const energy = await storage.energy_map.get(trooperzId)
    return {
        trooperzAttributes: stats,
        xp: xp,
        energy: energy,
        maxXp: storage.max_xp,
        maxEnergy: storage.max_energy 
    }
}

export async function getTrooperzMetadataWithContract (contract: ContractTypeTZIP, trooperzId: number): Promise<TrooperzMetadata|null> {
    const data = await contract.tzip12().getTokenMetadata(trooperzId)
    return data
}

export async function getContractStorage (tezos: TezosToolkit, contractAddress: string) {
    const contract: ContractTypeTZIP = await tezos.contract.at(contractAddress, tzip12 as TZIP12)
    const storage = await contract.storage()
    return storage
}

export async function getTrooperzToReveal (tezos: TezosToolkit, marketplaceContractAddress: string) {
    const storage = await getContractStorage(tezos, marketplaceContractAddress)
    return storage.to_reveal.map((item: any) => item.toNumber())
}

function genModeParams (mode: MissionMode, trooperzId: number, missionId: number | null = null) {
    if (mode === 'mission' && missionId) {
        const modeParams: ModeParams = {
            trooperz_id: Number(trooperzId),
            mission_id: Number(missionId)
        }
        return {
            [mode]: modeParams
        }
    }
    return {
        [mode]: Number(trooperzId)
    }
}

export async function setStrooperzMode (wallet: Wallet, trooperzId: number, mode: MissionMode, trainingRoomAddress: string, missionId:number | null = null) {
    const contract = await wallet.at(trainingRoomAddress)
    const modeParams = [genModeParams(mode, trooperzId,  missionId)]
    return contract.methods.set_trooperz_mode(modeParams).send()
}

export async function setTrooperzModeBatch (wallet: Wallet, batchParams: BatchParams[], trainingRoomAddress: string) {
    const contract = await wallet.at(trainingRoomAddress)
    const batchArray = []

    for (const params of batchParams) {
        const modeParams = [genModeParams(params.mode, params.trooperzId,  params.missionId)]
        batchArray.push({
            kind: OpKind.TRANSACTION,
            ...contract.methods.set_trooperz_mode(modeParams).toTransferParams() 
        })
    }
    const batch = await wallet.batch()
    return batch.with(batchArray as WalletParamsWithKind[]).send()
}

export async function setTrooperzModeBatchOneTransaction (wallet: Wallet, batchParams: BatchParams[], trainingRoomAddress: string) {
    const contract = await wallet.at(trainingRoomAddress)
    const batchArray = []
    const maxBuckets = 20
    let modeParams = []

    for (const params of batchParams) {
        modeParams.push(genModeParams(params.mode, params.trooperzId,  params.missionId))
        // modeParams is full or is last element
        if (modeParams.length === maxBuckets || batchParams.indexOf(params) === batchParams.length - 1) {
            batchArray.push({
                kind: OpKind.TRANSACTION,
                ...contract.methods.set_trooperz_mode(modeParams).toTransferParams() 
            })
            modeParams = []
        }
    }
    const batch = await wallet.batch()
    return batch.with(batchArray as WalletParamsWithKind[]).send()
}

export function generateUpdateOperatorsParams (tokenIds: number[], owner: string, operator: string) {
    const params = []
    for (const i in tokenIds) {
        const tokenId = tokenIds[i]
        const op = {
            add_operator: {
                owner: owner,
                operator: operator,
                token_id: tokenId
            }
        }
        params.push(op)
    }
    return params
}

export async function useConsumables (wallet: Wallet, userAddress: string, consumableArray: ConsumablesParams[], 
    contractUseConsAddress: string, contractConsTokenAddress: string, harvest = false, contractTrainingAddress?: string) {
    const contractUseCons = await wallet.at(contractUseConsAddress)
    const contractConsToken = await wallet.at(contractConsTokenAddress)
    const batchArray = []

    if (harvest && contractTrainingAddress) {
        const trooperIdToHarvest = new Set(consumableArray.map(item => item.trooperz_id))
        const contractTraining = await wallet.at(contractTrainingAddress)
        batchArray.push({
            kind: OpKind.TRANSACTION,
            ...contractTraining.methods.harvest_rewards([...trooperIdToHarvest]).toTransferParams() 
        })
    }
    for (const params of consumableArray) {
        const updateOperatorsParams = generateUpdateOperatorsParams([params.consumable_id], userAddress, contractUseConsAddress)
        batchArray.push({
            kind: OpKind.TRANSACTION,
            ...contractConsToken.methodsObject.update_operators(updateOperatorsParams).toTransferParams() 
        })
        batchArray.push({
            kind: OpKind.TRANSACTION,
            ...contractUseCons.methodsObject.use_consumable(params).toTransferParams() 
        })
    }

    const batch = await wallet.batch()
    return batch.with(batchArray as WalletParamsWithKind[]).send()
}

export async function bankDeposit (wallet: Wallet, contractBankAddress: string, contractTcoinAddress: string, 
    lotId: number, amount: number, lotConfig: LotConfig) {
    const contractBank = await wallet.at(contractBankAddress)
    const contractTcoin = await wallet.at(contractTcoinAddress)
    const batchArray = []
    const totalRequiredTcoin = lotConfig.tcoin_required * amount
    batchArray.push({
        kind: OpKind.TRANSACTION,
        ...contractTcoin.methods.approve(contractBankAddress, totalRequiredTcoin).toTransferParams() 
    })
    batchArray.push({
        kind: OpKind.TRANSACTION,
        ...contractBank.methods.deposit(amount, lotId).toTransferParams() 
    })
    const batch = await wallet.batch()
    return batch.with(batchArray as WalletParamsWithKind[]).send()
}


export async function bankHarvest (wallet: Wallet, contractBankAddress: string, lotId: number) {
    const contractBank = await wallet.at(contractBankAddress)
    return contractBank.methods.harvest(lotId).send()
}


export async function setStrooperzModeUpdateContract (wallet: Wallet, trooperzId: number, mode: MissionMode, trainingRoomAddress: string, oldTrainingRoomAddress:string, missionId:number | null = null) {
    const contract = await wallet.at(trainingRoomAddress)
    const oldContract = await wallet.at(oldTrainingRoomAddress)
    const modeParams = [genModeParams(mode, trooperzId,  missionId)]

    const batch = await wallet.batch()
    .withContractCall(oldContract.methods.remove_trooperz([trooperzId]))
    .withContractCall(contract.methods.set_trooperz_mode(modeParams))
    const batchOp = await batch.send();

    return batchOp
}

export async function harvestTrooperz (wallet: Wallet, trooperzId: number, trainingRoomAddress: string) {
    const contract = await wallet.at(trainingRoomAddress)
    return contract.methods.harvest_rewards([trooperzId]).send()
}

export async function removeTrooperz (wallet: Wallet, trooperzId: number, trainingRoomAddress: string) {
    const contract = await wallet.at(trainingRoomAddress)
    return contract.methods.remove_trooperz([trooperzId]).send()
}